import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/shared/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import PageTitle from "../../components/shared/PageTitle/PageTitle";
import ContactForm2 from "../../components/shared/ContactForm/ContactForm2";
import BackToOfferingButton from "../../components/shared/ButtonBackTo/BackToOfferingButton";
import AdditionalInformation from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";


const TimberIDAuthenticationPlatformPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.OfferingTimberIDAuthenticationPlatform.pageTitle;
    const sectionAnchor = routes.Offering.sections.offeringSection.anchor

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={pageTitle}
                parentTitles={[routes.Offering.pageTitle]}
                parentRoutes={[routes.Offering]}
                sectionAnchors={[sectionAnchor]}
            />

            <PageTitle>{pageTitle}</PageTitle>

            <ContactForm2 websiteSection={pageTitle}/>

            <BackToOfferingButton sectionAnchor={sectionAnchor}/>

            <AdditionalInformation typeMail={'sales'}/>

            <Footer/>

        </Layout>
    );
};

export default TimberIDAuthenticationPlatformPage;
